@import url("https://fonts.googleapis.com/css2?family=Mulish&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Big+Shoulders+Display:wght@500&family=Mulish&display=swap");

body {
  overflow-x: hidden;
}

/* Start Colours */

a {
  color: #019f47 !important;
  text-decoration: none !important;
}

.font1 {
  font-family: "Mulish" !important;
}

.font2 {
  font-family: "Big Shoulders Display" !important;
}

.text-primary {
  color: #373092 !important;
}

.text-secondary {
  color: #019f47 !important;
}

.text-light {
  color: #898989 !important;
}

.bg-light {
  background: #f5f5f5;
}

.bg-primary {
  background: #373092 !important;
}

.bg-secondary {
  background: #019f47 !important;
}

.hero-text {
  font-family: "Big Shoulders Display";
  font-style: normal;
  font-weight: 400;
  font-size: 50px;
  line-height: 115px;
  color: #ffffff;
  margin-top: 155px;
}

.hero-contactus {
  font-family: "Big Shoulders Display";
  font-style: normal;
  font-weight: 400;
  font-size: 50px;
  line-height: 115px;
  color: #ffffff;
}

.nav-border {
  border: none !important;
}

.hero-div {
  position: absolute;
  top: 50%;
  left: 20%;
  transform: translate(-50%, -50%);
}

.aboutus-div {
  position: absolute;
  top: 26%;
  left: 13%;
  transform: translate(-50%, -50%);
}

.contactus-div {
  position: absolute;
  top: 50%;
  left: 16%;
  transform: translate(-50%, -50%);
}

.containerhero {
  position: relative;
  text-align: center;
  color: white;
}

.cursor {
  cursor: pointer;
}

.arrow-carrer {
  float: right;
  margin-top: 3%;
}

.top-to-btm {
  position: relative;
}

.icon-position {
  position: fixed;
  bottom: 40px;
  right: 25px;
  z-index: 20;
}

.card-blog {
  height: 170px;
  width: auto;
  /*maintain aspect ratio*/
  max-width: 500px;
  width: 290px;
}

/* Start Colours */

.div-investore {
  background: #f4f4f4;
  width: 30%;
  margin: 1%;
  padding: 20px;
  padding-bottom: 2px;
}

/* Start Nav bar Style */

.dropdown {
  position: relative;
  display: inline-block;
}

.dropdown-content {
  display: none;
  position: absolute;
  background-color: #f9f9f9;
  min-width: 250px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  padding: 12px 16px;
  z-index: 1;
}

.dropdown:hover .dropdown-content {
  display: block;
}
.navbar {
  position: fixed;
  width: -webkit-fill-available;
  z-index: 9;
}

.nav-item {
  /* font-size: 1.25rem; */
  text-decoration: none;
  padding: 5px;
  display: inline-block;
  text-transform: capitalize;
}

.navitemtext {
  color: #302b86 !important;
}

.nav-item::after {
  content: "";
  display: block;
  width: 0;
  height: 2px;
  background: #019f47;
  transition: width 0.3s;
  color: white !important;
}

.nav-item:hover::after {
  width: 100%;
  transition: width 0.3s;
  color: white !important;
}
.nav-logo {
  width: 60%;
}

.icon-nav {
  font-size: 40px;
  cursor: pointer;
}

@media only screen and (max-width: 600px) {
  .icon-nav {
    font-size: 40px;
    font-weight: bold;
  }
}

/* End Nav bar Style */

/* Start Hero Image  */
.hero-img {
  position: absolute;
  left: 0px;
  top: 11%;
  z-index: -1;
}

.heroimage-section {
  height: 200px;
}

/* End Hero Image  */

.our-vision {
  background-image: url("./../assets/images/home-vision.png") !important;
  width: 100%;
  height: 100%;
  background-position: center center;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: cover;
}

.forward {
  background-image: url("./../assets/images/forward1.png") !important;
  background-position: center center;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: cover;
  max-width: 100%;
  height: auto;
}

@media screen and (max-width: 1070px) {
  .forward-Forward {
    position: relative;
    z-index: 1;
    color: #383092;
    font-family: "Big Shoulders Display";
    font-weight: 600;
    font-size: 150px;
    margin-left: 0%;
    margin-top: -50px;
  }
  .forward-Moving {
    color: #121212;
    margin-left: 51%;
    font-size: 66px;
  }
  .forward-Together {
    font-family: "Big Shoulders Display", Light;
    color: #121212;
    margin-left: 42%;
    margin-top: -4rem;
    font-size: 66px;
    position: relative;
    z-index: 1;
  }
}

.forward-text {
  padding: 20%;
}

.forward-Moving {
  color: #121212;
  margin-left: 62%;
  font-size: 66px;
  position: relative;
}

.forward-Forward {
  position: relative;
  z-index: 1;
  color: #383092;
  font-family: "Big Shoulders Display";
  font-weight: 600;
  font-size: 150px;
  margin-left: 28%;
  margin-top: -50px;
}

.forward-Together {
  font-family: "Big Shoulders Display", Light;
  color: #121212;
  margin-left: 55%;
  margin-top: -4rem;
  font-size: 66px;
  position: relative;
  z-index: 1;
}

/* Start News */
.new-img-1 {
  width: 100%;
}

.text-news {
  font-style: normal;
  font-size: 41px;
  line-height: 62px;
  width: 80%;
}

.text-single {
  color: #302a87;
}

.learn-more {
  font-weight: 600;
  margin-right: 2%;
}

.learn-more a:hover {
  color: #373092 !important;
}

/* End News*/

/* Start Card */
.card {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.view-more {
  text-decoration-line: underline;

  color: #302a87;
}

/* Start Card */

/* Event */

.event-div {
  background: #353190;
  height: 440px;
  margin-bottom: 15%;
}

.img-event {
  width: 75%;
  height: 100%;
}

.event-text {
  font-weight: 500;
  text-align: center !important;
  font-size: 35px;
  color: #302a87;
  width: 50%;
  margin-left: 26%;
}

.event-text-div {
  text-align: center !important;
}

/* Event */

/* Start  Footer*/

ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

li {
  line-height: 29px;
}

.social-icon {
  display: inline-flex;
}

.footer-2 {
  background-image: url("./../assets/images/footer-2.png") !important;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  padding: 6%;
}

/* End   Footer*/

/* Start investore */

.Awards-img {
  background-image: url("./../assets/images/border_awards.png") !important;
  background-position: center center;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: cover;
}

.investor-bg {
  background-image: url("./../assets/images/investor-bg.png") !important;
  background-position: center center;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: cover;
}

.invester-about-img {
  width: 80%;
}

.border-investore {
  border-top: 4px solid #373092;
  width: 15%;
  padding-bottom: 20px;
}

.investore-form-div {
  background: #373092;
}

.bg-1 {
  background-position: left;
  background-repeat: no-repeat;
  margin-top: auto;
}

.input-field {
  margin-top: 5%;
  padding: 12px 12px 12px 12px;
  width: 90%;
}

.submit-invest {
  margin: 6%;

  width: 28%;
}

/* End Investore */

/* Start Carrer */

.hr-2 {
  border: 1px solid #dfdfdf;
}

.careerimg1 {
  background-image: url("./../assets/images/img111.png") !important;
  width: 100%;
  height: 100%;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
}
.careerimg2 {
  background-image: url("./../assets/images/img222.png") !important;
  width: 100%;
  height: 100%;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
}

/* End Carrer*/

.bg-off {
  background: #373092;
}

.off-ul {
  text-align: left;
}

.offcanvas-end {
  width: 21% !important;
}

.icon-1 {
  float: left;
}

.icon-test {
  float: right;
}

.text-img-p {
  margin-left: 50%;
}

.Communities-learnmore {
  margin-top: 15% !important;
}

.inputfielddiv {
  margin-left: 6%;
}

.Communities-text {
  font-family: "Big Shoulders Display";
  font-style: normal;
  font-weight: 700;
  font-size: 150px;
  line-height: 239px;
  color: #373092;
}

.p-text {
  padding-left: 50%;
}

/* Responsive Media Query*/
@media only screen and (max-width: 600px) {
  .heroimage-section {
    height: 67px;
  }

  .offcanvas-end {
    width: 60% !important;
  }

  .Subscribe {
    margin-top: 13px;
    text-align: center;
  }
}

.hr-1 {
  width: 100%;
  background: #494397 !important;
  height: 3px !important;
  margin: auto;
}

.text-blog {
  width: auto;
  max-width: 500px;
  width: 250px;
  padding-bottom: 0px !important;
}
.text-blog p {
  padding-bottom: 0px !important;
}

.director-name {
  width: 65%;
}

.footer-second-text {
  font-family: "Big Shoulders Display" !important;
}

.p-text-footer {
  width: 71%;
}

.why-text {
  font-family: "Big Shoulders Display";
  line-height: 84px;
}

.Awards-div {
  box-shadow: 0px 4px 50px rgba(0, 0, 0, 0.05);
  background: white;
}

.contact-text {
  font-family: "Big Shoulders Display";
  font-family: "Big Shoulders Display";
  font-style: normal;
  font-weight: 400;
  font-size: 50px;
  line-height: 77px;

  color: #302a87;

  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.contact-input {
  background: #353190;
  border: none;
  border-bottom: 2px solid white;
  color: white;
}

.contact-input:focus {
  box-shadow: none;
  background: #353190;
  border: none;
  border-bottom: 2px solid white;
  color: white;
}

.contact-input::placeholder {
  color: white;
  opacity: 1;
  /* Firefox */
}

.contact-input:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: white;
}

.contact-input::-ms-input-placeholder {
  /* Microsoft Edge */
  color: white;
}

.contact-bg {
  background: #353190;
}

@media only screen and (max-width: 600px) {
  .footer-row {
    text-align: center;
  }

  .footer-row > .col-md-6 > p {
    width: 100% !important;
  }

  .div-investore {
    width: 100%;
  }

  .img-event {
    display: none;
  }

  .event-text {
    color: white;
  }

  .product-img {
    display: none;
  }

  .product-form {
    margin: 0;
    padding: 0;
  }
  .herodiv {
    padding-top: 21% !important;
  }
  .productformdiv {
    display: none;
  }
}

.medial-text {
  font-family: "Big Shoulders Display";
  font-style: normal;
  font-size: 40px;
  line-height: 77px;
  color: #373092;
}

.medical-ptag {
  font-family: "Mulish";
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 30px;

  color: #6c6c6c;
}

/* Over effect 2 */
.hover_color:hover::before {
  top: 50%;
}
.hover_color {
  position: relative;
  margin: 0;
  display: inline-block;
}

.hover_color::before {
  position: absolute;
  content: "";
  left: 0;
  top: 100%;
  right: 0;
  bottom: 0;
  background: rgba(55, 48, 146, 0.7);
  transform: matrix(-1, 0, 0, 1, 0, 0);
  transition: all 350ms;
}

.hover_color:hover::before {
  top: 0;
}

.hover_color img {
  margin-bottom: 0;
  width: 100%;
  height: 100%;
  margin-bottom: -3px;
}

.img-div {
  max-width: 500px;
  max-height: 591px;
}

.hover_color .hover_color-inner {
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.hover_title {
  transform: translateY(-4px);
  opacity: 0;
  transition: all 350ms;
  margin-top: 20%;
  color: white;
  text-align: center;
}

.hover_color a {
  position: absolute;
  content: "";
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  z-index: 1;
}

.hover_color:hover .hover_title {
  transform: translateY(0);
  opacity: 1;
}

/*  Over Effect */

.productformimg {
  background-image: url("./../assets/images/product-form-img.png") !important;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 100%;
}

.product-img {
  object-fit: cover;
  /* height: 100%; */
  height: 713px;
}

.card-div {
  height: 100%;
  width: 100%;
  overflow: hidden;
  margin: 0 auto;
}

.cardimg {
  width: 100%;
  transition: 0.5s all ease-in-out;
}

.product-form {
  margin-top: 15%;
}

/* --------------------------------------------------------------------------------------------- */
:root {
  --container-width: 800px;
  --body-bg: #c490d1;
  --body-color: #1a1a1a;
  --collapse-color: #1a1a1a;
  --collapse-line-color: #acacde;
  --collapse-icon-color: #000000;
}

details {
  background: var(--collapse-bg);
  color: var(--collapse-color);
  border-radius: 8px;
  margin-bottom: 24px;
  box-shadow: var(--collapse-box-shadow);
  border-bottom: 1px solid #acacde;
}

summary {
  display: grid;
  grid-template-columns: 1fr auto;
  align-items: center;
  cursor: pointer;
  list-style-type: none;
}

summary,
.details-info {
  padding: 1rem;
}

.details-info {
  border-top: 1px solid var(--collapse-line-color);
}

summary .icon {
  display: inline-block;
  width: 24px;
  height: 24px;
  transition: transform 0.26s;
  color: var(--collapse-icon-color);
}

details[open] summary .icon {
  transform: rotate(180deg);
}

@keyframes growAndFadeIn {
  0% {
    font-size: 0;
    opacity: 0;
  }

  90% {
    font-size: 1em;
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

/* ------------------- */
/*Asif Css ======================================================================================= */

.menu {
  height: 642px;
  width: 100%;
}

.support {
  color: #121212;
  margin-left: 47%;
  font-size: 66px;
  position: relative;
  z-index: 1;
}

.support1 {
  font-family: "Big Shoulders Display";
  color: #121212;
  font-size: 66px;
}

.right_section {
  position: relative;
  right: 13.7rem;
  top: 2.2rem;
}

.carousel {
  width: 48rem;
}

.carousal_image {
  width: 200px;
  height: 496px;
}

.para1 {
  z-index: 1;
  color: #727272;
  font-weight: 300;
  text-align: right;
  position: relative;
  right: 19%;
  font-family: "Mulish";
}

.carousel-control-prev-icon {
  background-image: url("../assets/images/arrow_right.png") !important;
  transform: scaleX(-1);
  margin-top: 35rem;
  position: relative;
  left: 15rem;
}

.carousel-indicators {
  display: none;
}

.carousel-control-next-icon {
  background-image: url("../assets/images/arrow_left.png") !important;
  transform: scaleX(-1);
  margin-top: 35rem;
  position: relative;
  right: 15rem;
}

.visually-hidden {
  display: none;
}

.virus_image img {
  width: 578px;
  height: 342px;
  position: relative;
  margin-top: -13rem;
  right: 4rem;
  bottom: 2rem;
}

.left_section {
  position: relative;
  right: 7rem;
  top: 5rem;
}

.arrow_icons {
  margin-top: 10rem;
  margin-left: -5rem;
}

.health_tag {
  font-family: "Big Shoulders Display", Light;
  color: #121212;
  margin-left: 35%;
  margin-top: -4rem;
  font-size: 66px;
  position: relative;
  z-index: 1;
}

.arrowleft,
.arrowright {
  color: #383092;
  font-size: 40px;
}

.arrowright {
  margin-left: 4rem;
}

.human_tag {
  position: relative;
  z-index: 1;
  color: #383092;
  font-family: "Big Shoulders Display";
  font-weight: 600;
  font-size: 150px;
  margin-left: 40%;
  margin-top: -50px;
}
.general-head{
  padding-top: 100px;
}
.general-head2{
  display: flex;
  align-items: center;
  gap: 35px;
}
.upload{
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
  border:2px solid #fff;
}
.upload2{
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
}

@media screen and (max-width: 1200px) {
  .left_section {
    position: relative;
    right: 12.7rem;
  }

  .carousal_image {
  }
}

@media screen and (max-width: 600px) {
  .slick-dots {
    display: none !important;
  }
  .carousel {
    width: 12rem !important;
  }
}

.divemobileslider {
  display: none;
}

/*Asif Css ======================================================================================= */

/* New Css */

.portfoliotext {
  line-height: 50px;
}

.map {
  -webkit-filter: grayscale(10%) !important;
}

.about-text-p {
  text-align: justify;
}

.carrertext {
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.109);
}

.imgiconcontact {
  width: 12%;
  margin-top: 10%;
}

.boxcontact {
  margin-right: 20%;
}

.navitem {
  border-bottom: 1px solid #019f47;
  margin-top: 15px;
  padding-bottom: 5px;
}

.card-img-top1 {
  /* height: 250px !important; */
  display: block;
  width: 100%;
  -o-object-fit: cover;
  object-fit: cover;
}

.newscard {
  /* height: 280px !important; */
}

.keycard {
  /* height: 490px; */
  /* display: flex;
  flex-direction: column; */
}
.productimg {
  height: 600px;
}

.bg-investore {
  background: #353190;
}

.divintern {
  /* margin-top: 22% !important; */
}

.herodiv {
  padding-top: 5%;
}

.homesectionfirst {
  margin-top: 20%;
}

.lefsidediv {
  margin-top: 95% !important;
  text-align: end;
}

.divslider {
  padding-top: 29%;
  background: rgb(241, 241, 241);
  padding-bottom: 8%;
  margin-bottom: 5%;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

