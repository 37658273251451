.carousel-control-next-icon,
.carousel-control-prev-icon {
  display: none;
}

.board-scroll {
  overflow-x: scroll;
  height: 500px;
  overflow-x: hidden;
}

.management-scroll {
  overflow-y: auto;
  height: 500px;
  overflow-x: hidden;
}

.awards-scroll {
  overflow-y: auto;
  height: 500px;
  overflow-x: hidden;
}

.slick-dots :before {
  display: none !important;
}

.about-card {
  height: 650px;
  cursor: pointer;
}

.about-img {
  height: 350px;
  cursor: pointer;
}

.slick-prev:before,
.slick-next:before {
  font-family: "slick";
  font-size: 27px !important;
  line-height: 2;
  opacity: 0.75;
  color: #373092 !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.slick-prev {
  left: -65px !important;
}

.slick-next {
  right: -65px !important;
}

.container1 {
  position: relative;
  text-align: center;
  color: white;
}

.bottom-left {
  position: absolute;
  bottom: 29%;
  left: 27%;
  width: 1% !important;
}
.bottom-left1 {
  position: absolute;
  bottom: 28%;
  left: 32%;
  width: 1% !important;
}

.top-left {
  position: absolute;
  top: 25%;
  left: 16%;
  width: 1% !important;
}

.top-left1 {
  position: absolute;
  top: 43%;
  left: 24%;
  width: 1% !important;
}
.top-left2 {
  position: absolute;
  top: 39%;
  left: 16%;
  width: 1% !important;
}

.top-right {
  position: absolute;
  top: 5%;
  right: 1%;
  width: 1% !important;
}

.top-right11 {
  position: absolute;
  top: 1%;
  right: 1%;
  width: 1% !important;
}

.bottom-right {
  position: absolute;
  bottom: 17%;
  right: 9%;
  width: 1% !important;
}

.bottom-right1 {
  position: absolute;
  bottom: 41%;
  right: 21%;

  width: 1% !important;
}

.bottom-right2 {
  position: absolute;
  bottom: 17%;
  right: 45%;
  width: 1% !important;
}

.centered {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 1% !important;
}

.india1 {
  position: absolute;
  top: 55%;
  left: 72%;
  transform: translate(-50%, -50%);
  width: 1% !important;
}

.india2 {
  position: absolute;
  top: 51%;
  left: 70%;
  transform: translate(-50%, -50%);
  width: 1% !important;
}

.india3 {
  position: absolute;

  top: 57%;
  left: 72%;
  transform: translate(-50%, -50%);
  width: 1% !important;
}

.india4 {
  position: absolute;
  top: 55%;
  left: 73.5%;
  transform: translate(-50%, -50%);
  width: 1% !important;
}

.centerred {
  position: absolute;
  top: 53%;
  left: 64%;
  transform: translate(-50%, -50%);
  width: 1% !important;
}

.centerredtop {
  position: absolute;
  top: 37%;
  left: 51%;
  transform: translate(-50%, -50%);
  width: 1% !important;
}

.centerredtop1 {
  position: absolute;
  top: 35%;
  left: 47.5%;
  transform: translate(-50%, -50%);
  width: 1% !important;
}


.values-row{
  display: flex;
  width: 100%;
}

.valuesbox{
  width: 20%;
}

.bod-img{
  max-width: 500px;
  max-height: 415px !important;
}