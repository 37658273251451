.report {
  background: #cacce9;
  color: #b5e2ee;
  border-radius: 17px;
  font-size: 18px;
  padding: 20px;
  margin: 0 20px 0 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 110px;
  cursor: pointer;
}
.report:hover {
  border: 3px solid #302b86;
  background: transparent;
}
.report-container {
  padding-bottom: 80px;
}
.report-main {
  display: flex;
  align-items: center;
  gap: 30px;
}
.report-name {
  font-family: "Big Shoulders Display";
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  color: #302b86;
  margin-bottom: 0 !important;
  text-align: center;
}
table.work-table {
  width: 100%;
  margin-top: 2%;
  border: 2px solid #fff;
  border-radius: 13px !important;
  border-spacing: 0;
  border-collapse: separate;
}
table.work-table td,
table.work-table th {
  padding: 20px;
  text-align: center;
  border-bottom: 2px solid #fff;
  border-right: 2px solid #fff;
}
tr:last-child th:last-child {
  border-right: none;
}
tr:first-child td:first-child {
  border-top-left-radius: 10px;
}
tr:last-child td:first-child {
  border-bottom-left-radius: 10px;
  border-bottom: none;
}
tr:first-child td:last-child {
  border-top-right-radius: 10px;
  border-right: none;
}
tr:last-child td:last-child {
  border-bottom-right-radius: 10px;
  border-bottom: none;
}
tr td:nth-child(2) {
    border-bottom: none;
}
  

.table-header {
  color: #fff;
  font-family: "Mulish";
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 135%;
  text-align: center;
  padding: 20px;
}
.table-data {
  color: #fff;
  font-family: "Mulish";
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 135%;
  width: 18vw;
}
.country-box {
  outline: none;
  height: 40px;
  border-radius: 8px;
  border: 1px solid #353190;
  color: #353190;
  font-family: "Mulish";
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
}
.complaint-name {
  color: #fff;
  font-weight: 600;
  font-family: "Mulish";
  font-size: 16px;
}
.event-list {
  padding: 0 3rem;
}
.event-upload-div {
  display: flex;
  align-items: center;
  gap: 20px;
}
.event-upload-div p {
  margin-bottom: 0 !important;
}

@media screen and (min-width: 320px) and (max-width: 920px) {
  .report-main {
    display: block !important;
  }
  .report {
    margin: 0 0 20px 0;
    border-radius: 12px;
  }
  table.work-table td, table.work-table th {
    padding: 10px 6px;
  }
  .table-header {
    font-size: 14px;
  }
  .table-data {
    font-size: 12px;
  }
  .report-image {
    width: 80px;
  }
  .country-box {
    font-size: 12px;
    width: 28vw;
  }
  .event-list {
    padding: 0 2rem;
  }
}
