.icon-nav {
  display: none !important;
}
.divslider {
  display: none !important;
}

.productnamemobile {
  display: none !important;
}
.mobileproductimg {
  display: none !important;
}

.applydiv {
  display: none !important;
}

.mobilediv {
  display: none;
}


.valuefordesktop{
  display: block;
}

.valueformobile{
  display: none;
}
@media only screen and (max-width: 600px) {
  .valuefordesktop{
    display: none;
  }
  .valueformobile{
    display: block;
  }
  .displaynone {
    display: none !important;
  }
  .navbar-nav {
    display: none !important;
  }

  .icon-nav {
    display: block !important;
  }

  .virus_image {
    display: none !important;
  }
  .section-2 {
    padding-top: 27%;
  }

  .section-1-p {
    margin: 0px !important;
    padding: 0px !important;
    padding-bottom: 23px !important;
  }

  .hero-text {
    display: none;
  }
  .slick-arrow {
    display: none !important;
  }

  .slick-dots :before {
    display: block !important;
  }
  .herodiv {
    padding-top: 21%;
  }
  .cardaboutus {
    width: 80% !important;
  }
  .contact-text {
    margin: 0px !important;
    padding: 0px !important;
    text-align: center !important;
  }
  .contact-bg {
    text-align: center;
  }
  .social-media-icons {
    padding-top: 14px !important;
    margin-right: 0px !important;
    margin-bottom: 16px;
  }
  .contact-text span {
    margin-left: 0px !important;
  }
  .productnamemobile {
    display: block !important;
  }
  .productname {
    display: none;
  }
  .copy-right {
    text-align: center !important;
  }
  .contact-text {
    font-size: 37px !important;
  }
  .investordiv {
    text-align: center !important;
  }
  .investoretext1 {
    width: 100% !important;
  }
  .prometarsdiv {
    flex-direction: column-reverse;
  }
  .imgproduct2 {
    display: none;
  }
  .mobileproductimg {
    display: block !important;
  }
  .medial-text {
    font-size: 25px !important;
    line-height: unset !important;
    color: #373092;
  }
  .medical-ptag {
    font-size: unset;
  }
  .productaboutusdiv {
    padding-bottom: 0px !important;
    margin-bottom: 0px !important;
  }
  .divservice {
    display: none !important;
  }
  .cardaboutus {
    width: 94% !important;
  }
  .internal {
    display: none !important;
  }
  .applydiv {
    display: block !important;
  }
  .offcanvas.offcanvas-start {
    width: 70% !important;
  }
  .mobilediv {
    display: block !important;
  }
  .Leveraging {
    font-family: "Big Shoulders Display";
    left: 10%;
    position: relative;
  }
  .Science {
    position: relative;
    left: 20px;
    z-index: 1;
    color: #383092;
    font-family: "Big Shoulders Display";
    font-weight: 600;
    font-size: 56px;
  }
  .Humankind {
    left: 10%;
    position: relative;
    font-family: "Big Shoulders Display";
  }
  .blogbox {
    padding-top: 24px;
  }
  .Staydiv {
    padding-top: 19px !important;
    padding-bottom: 5px !important;
  }
  .viewmore {
    padding-top: 15px !important;
    padding-bottom: 25px !important;
  }
  .divblogsmobile {
    padding: 0px !important;
    margin-bottom: 0px !important;
  }
  .FooterSecondmobile {
    padding: 0px !important;
    margin: 0px !important;
  }
  .divblogsmobile1 {
    margin: 0px !important;
  }
  .diviconhome {
    margin: 0px !important;
  }
  .Eugiaabout {
    margin-top: 22px;
  }
  .divaboutpage {
    padding-left: 15px !important;
    padding-right: 15px !important;
  }
  .promoterdivmobile {
    padding-left: 15px !important;
    padding-right: 37px !important;
    text-align: justify;
  }
  .divproductm {
    padding-left: 15px !important;
    padding-right: 15px !important;
  }
  .Filterdiv {
    text-align: end !important;
    padding-right: 30px !important;
    margin-top: -50px;
    padding-top: 13px;
  }
  .Filterdiv1 {
    text-align: start;
    padding-left: 22px;
    padding-top: 13px;
  }
  .Category-btn {
    background-color: #019f47;
  }
  .careerdiv {
    padding-left: 25px !important;
    padding-right: 25px !important;
  }
  .Current {
    padding-top: 0px !important;
    padding-bottom: 0px !important;
  }
  .divemobileslider {
    background: rgb(241, 241, 241);
    height: 300px;
    padding-top: 27px;
  }
  .ptaghome {
    color: #727272;
    font-weight: 300;
    text-align: right;
    font-size: 71%;
    padding-top: 14%;
    position: relative;
    left: 9%;
  }
  .Leveragingtextdiv {
    padding-top: 23%;
  }
  .divslider {
    display: block !important ;
  }
  .portfoliotext {
    line-height: 35px !important;
  }
}

@media screen and (max-width: 1382px) and (min-width: 1203px) {
  .support {
    margin-left: 41% !important;
  }
  .health_tag {
    margin-left: 26% !important;
  }

  .human_tag {
    margin-left: 30% !important;
  }

  .forward-Moving {
    margin-left: 56% !important;
  }
  .forward-Together {
    margin-left: 47% !important;
  }
  .forward-Forward {
    margin-left: 16% !important;
  }
}
